<template>
  <v-dialog
    v-model="show"
    width="350"
    persistent
  >
  <v-card flat light>
      <v-card-title class="text-h5">
        <span> RELEASE PAYMENT </span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="grey lighten-3">
        <div class="pt-5">
          <span> Release Funds according to percentage of order completed </span>
        </div>
        <div class="d-flex mt-3">
          <v-btn fab tile small class="mr-2 rounded" :disabled="parseInt(percentage) === 0" @click="reducePercentage"> - </v-btn>
          <div style="width: 80%">
            <v-btn large block > {{ percentage }}%</v-btn>
          </div>
          <v-btn fab tile small class="ml-2 rounded" :disabled="parseInt(percentage) >= 100" @click="increasePercentage"> + </v-btn>
        </div>

        <v-alert
          :type="actionClass"
          class="mr-15 mt-2"
          v-if="message"
          dismissible
          @input="alertInput"
        >
          {{ message }}
        </v-alert>

        <div class="mt-10">
          <div class="d-flex">
            <span> 0%</span>
            <v-spacer></v-spacer>
            <span> 100%</span>
          </div>
          <v-slider
            max="100"
            min="0"
            v-model="percentage"
            rounded
            :value="percentage"
            track-color="grey"
            color="orange darken-5"
          ></v-slider>
        </div>
      </v-card-text>
      <v-card-text>
        <v-row class="mt-3">
          <v-col>
            <span> Released to writer:</span>
          </v-col>
          <v-col class="text-left">
            ${{ amountReleased }} ({{ percentageReleased }}%)
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <span> Release up to:</span>
          </v-col>
          <v-col class="text-left">
            ${{ calRelease }} ({{ percentage }}%)
          </v-col>
        </v-row>
      </v-card-text>
    <v-divider class="mt-3"></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn :color="essayGreen" dark @click="submit" :loading="loading"> RELEASE PAYMENT </v-btn>
      </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: 'ReleaseModal',
  mixins: [colorMxn,validationMxn],
  props: ['show', 'order'],
  data() {
    return {
      percentage: 50,
      amountReleased: 0.00,
      percentageReleased: 0.00,
      message: '',
      actionClass: 'success',
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getReload']),
    calRelease() {
      const result = this.order.amount * (this.percentage/100)
      return this.roundToTwo(result);
    }
  },
  watch: {
    // getReload(val) {
    // },
  },
  mounted() {
    this.percentage = this.order.percentage_released;
    this.amountReleased = this.order.amount_released;
    this.percentageReleased = this.order.percentage_released;
  },
  methods: {
    ...mapMutations(['setReload']),
    ...mapActions(['refreshAuthToken', 'getBalance']),
    reducePercentage() {
      this.percentage = parseInt(this.percentage) - 25
    },
    increasePercentage() {
      this.percentage = parseInt(this.percentage) + 25
    },
    roundToTwo(num) {    
      return +(Math.round(num + "e+2")  + "e-2");
    },
    alertInput(val) {
      if (!val) {
        this.message = val;
      }
    },
    reloadMutation() {
      this.setReload(!this.getReload);
    },
    async submit() {
      if (this.percentage <= this.order.percentage_released) {
        this.message = 'Percentage cannot be less that what you have already released';
        this.actionClass = 'error';
        return;
      }
      this.loading = true;
      const payload = {
        percentage_released: this.percentage,
        amount_released: this.calRelease,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      const response = await this.performPutActions(fullPayload);
      this.message = response.status ? 'Amount Released Successfully' : ' Release Failed';
      this.actionClass = response.status ? 'success' : 'error';
      this.loading = false;
      this.amountReleased = response.data.amount_released;
      this.setReload(true);
      await this.getBalance();

      setTimeout(() => {
        this.$emit('close');
      }, 1000);
    }
  },
}
</script>